import React from "react";
import { useParams } from "react-router";
import { H4 } from "app/components/Typography";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { NavFilterButton } from "app/components/Button/NavFilterButton";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetCategoryQuery } from "services/graphql";
import Breadcrumbs from "app/components/Breadcrumbs";
import { FilterIconButton } from "app/components/Button/IconButton/FilterIconButton";
import { ScreenGtMd } from "app/components/MediaQueries";
import { Helmet } from "react-helmet-async";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { Container, CenterWrapper, Title } from "../styles";

interface ParamTypes {
  categorySlug: string;
}

type Props = {
  children?: React.ReactNode;
};

export const CategoriesBodyWrapper = ({ children }: Props) => {
  const { url } = useRouteMatch();
  const { categorySlug } = useParams<ParamTypes>();
  const history = useHistory();
  const { data: { categories } = {} } = useGetCategoryQuery({
    variables: {
      slug: categorySlug,
    },
  });
  const categoryData = categories?.[0];

  if (!categoryData) {
    return null;
  }

  const doesCategoryHavePrograms = categoryData.programsV2.length > 1;
  let algoliaIndexName;

  if (url.includes("/classes")) {
    algoliaIndexName = AlgoliaIndexes.classes;
  } else if (url.includes("/programs")) {
    algoliaIndexName = AlgoliaIndexes.programs;
  }

  return (
    <>
      <Helmet title="Results for Categories | Online Dance Classes and Tutorials" />

      <CenterWrapper>
        <Container>
          <Breadcrumbs onClick={() => history.push("/library/categories")}>
            &lt; Back to Categories
          </Breadcrumbs>
          <Div mb={4}>
            <Flex width="100%" justifyContent="space-between">
              <Flex width="100%" alignItems="center">
                <Title>Results</Title>
                {doesCategoryHavePrograms && (
                  <Flex maxWidth="624px" width="100%">
                    <NavFilterButton
                      text="classes"
                      isSelected={url.includes("/classes")}
                      onClick={() => history.push("classes")}
                    />
                    <NavFilterButton
                      text="programs"
                      isSelected={url.includes("/programs")}
                      onClick={() => history.push("programs")}
                    />
                  </Flex>
                )}
              </Flex>
              {/* MobileFilterButton will render on screens less than medium */}
              {algoliaIndexName && (
                <ScreenGtMd>
                  <Div>
                    <Flex justifyContent="center" alignItems="center">
                      <FilterIconButton indexName={algoliaIndexName} />
                    </Flex>
                  </Div>
                </ScreenGtMd>
              )}
            </Flex>
            <H4>For {categoryData.name}</H4>
          </Div>
          {children}
        </Container>
      </CenterWrapper>
    </>
  );
};
